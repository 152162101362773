import React, { useEffect, useRef } from "react";
import useHealthProfile from "hooks/useHealthProfile";
import { useHistory, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import CSSLoader from "components/cssLoader/CSSLoader";
import Spacer from "components/Spacer/Spacer";
import HealthProfileQuestion from "../../HealthProfileQuestion";
import HealthProfileRadio from "../../HealthProfileRadio";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import Input from "components/inputs/Input";
import { HealthProfileSexualActivitySchema } from "helpers/validationSchema";
import FormikErrorFocus from "formik-error-focus";
import SimpleHeader from "components/header/SimpleHeader";

const ACTIVITY = ["Inactive", "Occasionally", "Regularly"];
const PARTNER = ["Multiple Partners", "Male Partner", "Female Partner"];
const YES_NO = ["Yes", "No"];

function SexualActivity() {
  const formRef = useRef();
  const history = useHistory();
  const { health_id } = useParams();

  const {
    healthPayload,
    postHealthProfile,
    postingHealthProfile,
  } = useHealthProfile();

  useEffect(() => {
    if (
      healthPayload?.health_profile &&
      healthPayload?.health_profile?.sexual_activity
    ) {
      formRef?.current?.setValues({
        activity:
          healthPayload?.health_profile?.sexual_activity?.sexual_activity ?? "",
        partner: healthPayload?.health_profile?.sexual_activity?.partner ?? "",
        safe_sex_practice:
          healthPayload?.health_profile?.sexual_activity?.safety ?? "",
        birth_control_type:
          healthPayload?.health_profile?.sexual_activity?.birth_control_type ??
          "",
      });
    }
  }, [healthPayload]);

  function completed() {
    history.goBack();
  }

  function onSave(values) {
    let payload = {
      sexual_activity: {
        sexual_activity: values?.activity,
        partner: values?.partner,
        safety: values?.safe_sex_practice,
        birth_control_type: values?.birth_control_type,
      },
    };
    postHealthProfile({ healthId: health_id, payload, completed });
  }
  return (
    <div className="h-screen bg-veryLightBlue">
      <Formik
        innerRef={formRef}
        onSubmit={onSave}
        initialValues={{
          activity: "",
          partner: "",
          safe_sex_practice: "",
          birth_control_type: "",
        }}
        validationSchema={HealthProfileSexualActivitySchema}
      >
        {({ values, submitCount, errors, setFieldValue, setValues }) => (
          <Form className="h-full">
            <SimpleHeader
              showBackButton
              title="Sexual Activity"
              rightActionView={
                <button
                  disabled={postingHealthProfile}
                  type={"submit"}
                  className="text-2xl font-bold text-orange"
                >
                  {postingHealthProfile ? (
                    <CSSLoader className="w-8 h-8" color="orange" />
                  ) : (
                    "Save"
                  )}
                </button>
              }
            />
            <div className="p-12 overflow-y-auto bg-white h-eliminateHeaderWithBottomTab md:h-eliminateHeader">
              <MedicalAssistant
                content={<p>Please tell us about your sexual activity.</p>}
              />
              <Spacer height={26} />
              <HealthProfileQuestion text={"Sexual Activity"} />
              <div className={`flex items-center mt-8 flex-wrap`}>
                {ACTIVITY?.map((activity, idx) => {
                  return (
                    <div className="flex flex-1 mb-8 mr-6" key={idx}>
                      <HealthProfileRadio
                        text={activity}
                        selected={values?.activity === activity}
                        onClick={() => {
                          if (activity === "Inactive") {
                            setValues({
                              activity,
                              partner: "",
                              safe_sex_practice: "",
                              birth_control_type: "",
                            });
                          } else {
                            setFieldValue("activity", activity);
                          }
                        }}
                      />
                    </div>
                  );
                })}
              </div>
              {submitCount && errors?.activity ? (
                <p className="mt-4 text-base font-bold text-red">
                  {errors?.activity}
                </p>
              ) : null}
              {values?.activity?.length && values?.activity !== "Inactive" ? (
                <>
                  <Spacer height={26} />
                  <HealthProfileQuestion text={"Partner"} />
                  <div className={`flex items-center mt-8 flex-wrap`}>
                    {PARTNER?.map((partner, idx) => {
                      return (
                        <div className="flex flex-1 mb-8 mr-6" key={idx}>
                          <HealthProfileRadio
                            text={partner}
                            selected={values?.partner === partner}
                            onClick={() => setFieldValue("partner", partner)}
                          />
                        </div>
                      );
                    })}
                  </div>
                  {submitCount && errors?.partner ? (
                    <p className="mt-4 text-base font-bold text-red">
                      {errors?.partner}
                    </p>
                  ) : null}
                  <Spacer height={26} />
                  <HealthProfileQuestion
                    text={"Do you practice safe sex (protected from STD's)?"}
                  />
                  <div className={`flex items-center mt-8 flex-wrap`}>
                    {YES_NO?.map((option, idx) => {
                      return (
                        <div className="flex flex-1 mb-8 mr-6" key={idx}>
                          <HealthProfileRadio
                            text={option}
                            selected={values?.safe_sex_practice === option}
                            onClick={() =>
                              setFieldValue("safe_sex_practice", option)
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                  {submitCount && errors?.safe_sex_practice ? (
                    <p className="mt-4 text-base font-bold text-red">
                      {errors?.safe_sex_practice}
                    </p>
                  ) : null}
                  <Spacer height={26} />
                  <Input
                    labelstyles={{
                      fontWeight: "500",
                      textOpacity: 1,
                      color: "rgba(16, 19, 71, 1)",
                    }}
                    label="Types of birth control used"
                    id="birth_control_type"
                    name="birth_control_type"
                  />
                </>
              ) : null}
            </div>
            <FormikErrorFocus align={"bottom"} ease={"linear"} duration={500} />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default SexualActivity;
