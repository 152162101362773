import ApiConfig from "api/config";
import constants from "helpers/constants";

const currentUserAction = {
  method: "GET",
  endpoint: ApiConfig.urls.user.userDetails,
};

const userProfileAction = {
  method: "GET",
  endpoint: ApiConfig.urls.user.profile,
};

const userLogoutSessionAction = {
  method: "DELETE",
  endpoint: ApiConfig.urls.user.logoutSession,
};

const getUserHealthProfileAction = (healthId) => ({
  method: "GET",
  endpoint: ApiConfig.urls.user.healthProfile(healthId),
});

const updateUserHealthProfileAction = ({ healthId, payload }) => ({
  method: "PUT",
  endpoint: ApiConfig.urls.user.healthProfile(healthId),
  body: payload,
});

const getInsuranceProviders = (
  state,
  speciality,
  query,
  isSecondary = false
) => ({
  method: "GET",
  endpoint: `${
    ApiConfig.urls.user.getInsuranceProviders
  }${state}/insurances.json?speciality=${speciality}&q=${query}${
    isSecondary ? "&secondary_insurance=true" : ""
  }`,
});

const getInsuranceProvidersForDerm = (
  state,
  speciality,
  query,
  providerId,
  isSecondary = false
) => ({
  method: "GET",
  endpoint: `${
    ApiConfig.urls.user.getInsuranceProviders
  }${state}/insurances.json?speciality=${speciality}&q=${query}&practice_user_id=${providerId}${
    isSecondary ? "&secondary_insurance=true" : ""
  }`,
});

const updateBasicInfoAction = ({ userId, payload }) => ({
  method: "PUT",
  endpoint: `${ApiConfig.urls.user.updateBasicInfo}${userId}.json`,
  body: payload,
});

const updateProfileAction = (profileFormData) => ({
  method: "PUT",
  endpoint: ApiConfig.urls.user.updateProfile,
  body: profileFormData,
});

const updateProfileImageAction = (profileFormData) => ({
  method: "PUT",
  endpoint: ApiConfig.urls.imageUpload.uploadProfileImage,
  body: profileFormData,
});

const recommendProvidersAction = (speciality) => ({
  method: "GET",
  endpoint: ApiConfig.urls.user.recommendedProvider(speciality),
});

const availableProvidersAction = (speciality) => ({
  method: "GET",
  endpoint: ApiConfig.urls.user.availableProvider(speciality),
});

const availableUpdateProvidersAction = {
  method: "GET",
  endpoint: ApiConfig.urls.user.availableUpdateProviders,
};

const switchProviderAction = ({ payload, providerId, speciality }) => ({
  method: "PUT",
  endpoint: `${ApiConfig.urls.user.switchProvider}/available_providers/${providerId}/switch?speciality=${speciality}`,
  body: payload,
});

const addInsuranceAction = ({ formData, userId }) => ({
  method: "POST",
  endpoint: ApiConfig.urls.user.addInsurance + userId + "/add-insurance.json",
  body: formData,
});

const selectProvider = (payload) => ({
  method: "PUT",
  endpoint: ApiConfig.urls.user.selectProvider + `${payload.userId}.json`,
  body: payload.postbody,
});

const getPharmacyList = (payload) => ({
  method: "POST",
  endpoint: ApiConfig.urls.user.getPharmacyList,
  body: payload,
});

const updatePharmacy = (payload) => ({
  method: "POST",
  endpoint: ApiConfig.urls.user.updatePharmacy,
  body: payload,
});

const addChild = ({ accountId, payload }) => ({
  method: "POST",
  endpoint: ApiConfig.urls.user.addChild + accountId + "/patients.json",
  body: payload,
});

const sendConversation = ({ caseId, payload }) => ({
  method: "POST",
  endpoint:
    ApiConfig.urls.user.sendConversation + caseId + "/conversations.json",
  body: payload,
});

const updateConversation = ({ caseId, payload, conversationId }) => ({
  method: "PUT",
  endpoint:
    ApiConfig.urls.user.sendConversation +
    caseId +
    "/conversations/" +
    conversationId?.toString() +
    ".json",
  body: payload,
});
const reloadConversation = (id) => ({
  method: "GET",
  endpoint: ApiConfig.urls.user.refreshConversation(id),
});
const getPatientsAction = (userId) => ({
  method: "GET",
  endpoint: `${ApiConfig.urls.user.getPatients}${userId}/patients.json`,
});

const searchMedicalConditionAction = ({ condition, searchText, type }) => ({
  method: "GET",
  endpoint: `${ApiConfig.urls.user.serachMedicalCondition}${
    type && type === "profile" ? `profile-${condition}` : condition
  }?q=${searchText}`,
});

const addMedicalConditionAction = ({ condition, payload }) => ({
  method: "POST",
  endpoint: ApiConfig.urls.user.sendMedicalCondition + condition,
  body: payload,
});

const appVersionAction = (payload) => ({
  method: "POST",
  endpoint: ApiConfig.urls.user.version,
  headers: {
    "App-Version": constants.CURRENT_APP_VERSION,
  },
  body: payload,
});

const updateFirebaseTokenAction = (payload) => ({
  method: "POST",
  body: payload,
  endpoint: ApiConfig.urls.user.updateFirebaseToken,
});

const sendMailAction = (payload) => ({
  method: "POST",
  body: payload,
  endpoint: ApiConfig.urls.user.sendMail,
});

const subscribePCPAction = (payload) => ({
  method: "POST",
  body: payload,
  endpoint: ApiConfig.urls.user.subscribeToPCP,
});

const getGeoDataFromZipcodeAction = (zipcode) => ({
  method: "GET",
  endpoint: ApiConfig.urls.user.getGeoData(zipcode),
  config: { skipAuth: false },
});

const getGeoDataFromZipcodeNoAuthAction = (zipcode) => ({
  method: "GET",
  endpoint: ApiConfig.urls.user.getGeoData(zipcode),
  config: { skipAuth: true },
});

const getProvidersFromPracticeIdAction = (pid) => ({
  method: "GET",
  endpoint: ApiConfig.urls.user.getProvidersFromPracticeId(pid),
});

const postReviewAction = (payload) => ({
  method: "POST",
  body: payload,
  endpoint: ApiConfig.urls.user.postReview,
});

const viewProviderProfileAction = (case_id, pid) => ({
  method: "GET",
  endpoint: ApiConfig.urls.user.viewProviderProfile(case_id, pid),
});

//Provider Details will be not requiring Case ID
//In the scenario, where we don't have case Id, Can use this API
const viewProviderDetailsAction = (pid) => ({
  method: "GET",
  endpoint: ApiConfig.urls.user.viewProviderDetails(pid),
});

const checkInsuranceNetworkAction = (speciality, patientId) => ({
  method: "GET",
  endpoint: ApiConfig.urls.user.checkInsuranceNetwork(speciality, patientId),
});

const checkInsuranceNetworkActionForDerm = (
  speciality,
  patientId,
  providerId
) => ({
  method: "GET",
  endpoint: `${ApiConfig.urls.user.checkInsuranceNetwork(
    speciality,
    patientId
  )}&practice_user_id=${providerId}`,
});

const getIncompleteVisits = {
  method: "GET",
  endpoint: ApiConfig.urls.visit.incompleteVisits,
};
const getSubmittedVisits = {
  method: "GET",
  endpoint: ApiConfig.urls.visit.submittedVisits,
};

export {
  currentUserAction,
  userProfileAction,
  userLogoutSessionAction,
  recommendProvidersAction,
  availableProvidersAction,
  getInsuranceProviders,
  getInsuranceProvidersForDerm,
  getUserHealthProfileAction,
  updateUserHealthProfileAction,
  getPatientsAction,
  addInsuranceAction,
  selectProvider,
  getPharmacyList,
  updatePharmacy,
  updateProfileAction,
  updateProfileImageAction,
  updateBasicInfoAction,
  addChild,
  sendConversation,
  updateConversation,
  reloadConversation,
  searchMedicalConditionAction,
  addMedicalConditionAction,
  appVersionAction,
  updateFirebaseTokenAction,
  switchProviderAction,
  availableUpdateProvidersAction,
  sendMailAction,
  subscribePCPAction,
  getGeoDataFromZipcodeAction,
  getGeoDataFromZipcodeNoAuthAction,
  getProvidersFromPracticeIdAction,
  postReviewAction,
  viewProviderProfileAction,
  viewProviderDetailsAction,
  checkInsuranceNetworkAction,
  checkInsuranceNetworkActionForDerm,
  getIncompleteVisits,
  getSubmittedVisits,
};
