import React, { useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import useHealthProfile from "hooks/useHealthProfile";
import { Form, Formik } from "formik";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import HealthProfileQuestion from "../../HealthProfileQuestion";
import Spacer from "components/Spacer/Spacer";
import HealthProfileRadio from "../../HealthProfileRadio";
import Input from "components/inputs/Input";
import CSSLoader from "components/cssLoader/CSSLoader";
import { HealthProfileRecreationalDrugsValidationSchema } from "helpers/validationSchema";
import FormikErrorFocus from "formik-error-focus";
import SimpleHeader from "components/header/SimpleHeader";

const DRUGS_USE = ["Never", "Occasionally", "Daily", "Previously/Quit"];
const FREQUENCY = ["Day", "Week", "Month"];

function RecreationalDrugs() {
  const formRef = useRef();
  const history = useHistory();
  const { health_id } = useParams();

  const {
    healthPayload,
    postHealthProfile,
    postingHealthProfile,
  } = useHealthProfile();

  useEffect(() => {
    if (
      healthPayload?.health_profile &&
      healthPayload?.health_profile?.drug_usage
    ) {
      formRef?.current?.setValues({
        drug_usage: healthPayload?.health_profile?.drug_usage?.drug_usage ?? "",
        drug_type: healthPayload?.health_profile?.drug_usage?.drug_type ?? "",
        no_of_drugs:
          healthPayload?.health_profile?.drug_usage?.usage_in_number ?? "",
        frequency: healthPayload?.health_profile?.drug_usage?.frequency ?? "",
      });
    }
  }, [healthPayload]);

  function completed() {
    history.goBack();
  }

  function onSave(values) {
    let payload = {
      drug_usage: {
        drug_usage: values?.drug_usage,
        usage_in_number: values?.no_of_drugs,
        frequency: values?.frequency,
        drug_type: values?.drug_type,
      },
    };
    postHealthProfile({ healthId: health_id, payload, completed });
  }
  return (
    <div className="h-screen bg-veryLightBlue">
      <Formik
        innerRef={formRef}
        onSubmit={onSave}
        initialValues={{
          drug_usage: "",
          drug_type: "",
          no_of_drugs: "",
          frequency: "",
        }}
        validationSchema={HealthProfileRecreationalDrugsValidationSchema}
      >
        {({ values, submitCount, errors, setFieldValue, setValues }) => (
          <Form className="h-full">
            <SimpleHeader
              showBackButton
              title="Recreational Drugs"
              rightActionView={
                <button
                  disabled={postingHealthProfile}
                  type={"submit"}
                  className="text-2xl font-bold text-orange"
                >
                  {postingHealthProfile ? (
                    <CSSLoader className="w-8 h-8" color="orange" />
                  ) : (
                    "Save"
                  )}
                </button>
              }
            />
            <div className="p-12 overflow-y-auto bg-white h-eliminateHeaderWithBottomTab md:h-eliminateHeader">
              <MedicalAssistant
                content={<p>Please tell us in general about any drug usage.</p>}
              />
              <Spacer height={26} />
              <HealthProfileQuestion text={"Do you currently use tobacco?"} />
              <div className={`flex items-center mt-8 flex-wrap`}>
                {DRUGS_USE?.map((usage, idx) => {
                  return (
                    <div className="flex flex-1 mb-8 mr-6" key={idx}>
                      <HealthProfileRadio
                        text={usage}
                        selected={values?.drug_usage === usage}
                        onClick={() => {
                          if (usage === "Never") {
                            setValues({
                              drug_usage: usage,
                              drug_type: "",
                              no_of_drugs: "",
                              frequency: "",
                            });
                          } else {
                            setFieldValue("drug_usage", usage);
                          }
                        }}
                      />
                    </div>
                  );
                })}
              </div>
              {submitCount && errors?.drug_usage ? (
                <p className="mt-4 text-base font-bold text-red">
                  {errors?.drug_usage}
                </p>
              ) : null}
              {values?.drug_usage?.length && values?.drug_usage !== "Never" ? (
                <>
                  <Spacer height={26} />
                  <Input
                    labelstyles={{
                      fontWeight: "500",
                      textOpacity: 1,
                      color: "rgba(16, 19, 71, 1)",
                    }}
                    label="Types of drugs?"
                    id="drug_type"
                    name="drug_type"
                  />
                  <Spacer height={26} />
                  <Input
                    labelstyles={{
                      fontWeight: "500",
                      textOpacity: 1,
                      color: "rgba(16, 19, 71, 1)",
                    }}
                    type="number"
                    label="Usage in number"
                    id="no_of_drugs"
                    name="no_of_drugs"
                    min="1"
                  />
                  <Spacer height={26} />
                  <HealthProfileQuestion text={"Frequency"} />
                  <div className={`flex items-center mt-8 flex-wrap`}>
                    {FREQUENCY?.map((frequency, idx) => {
                      return (
                        <div className="flex flex-1 mb-8 mr-6" key={idx}>
                          <HealthProfileRadio
                            text={frequency}
                            selected={values?.frequency === frequency}
                            onClick={() =>
                              setFieldValue("frequency", frequency)
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                  {submitCount && errors?.frequency ? (
                    <p className="mt-4 text-base font-bold text-red">
                      {errors?.frequency}
                    </p>
                  ) : null}
                </>
              ) : null}
            </div>
            <FormikErrorFocus align={"bottom"} ease={"linear"} duration={500} />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default RecreationalDrugs;
