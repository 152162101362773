import React, { useEffect, useRef, useState } from "react";
import StepScreenContainer from "components/visitCommon/StepScreenContainer";
import { useDermatologyVisitFlow } from "hooks/useVisitFlow";
import usePatients from "hooks/visit/usePatients";
import { updateBasicInfoAction } from "api/actions/UserActions";
import useVisitStepChange from "hooks/useVisitStepChange";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import { useDispatch } from "react-redux";
import { dermatology_sub_steps } from "helpers/enum";
import useUpdateDermatologyVisit from "hooks/visit/useUpdateDermatologyVisit";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import { ErrorMessage, Form, Formik } from "formik";
import {
  decimalRestriction,
  getFeetInInches,
  getInchesInFeet,
} from "helpers/metricConversion";
import Input from "components/inputs/Input";
import { updateBasicInfoPBSchema } from "helpers/validationSchema";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useApiMutation } from "hooks/useApiMutation";
import RegularRadioSelect from "components/radioButtonCard/RegularRadioSelect";
import FormikErrorFocus from "formik-error-focus";
import BirthInput from "components/inputs/BirthInput";
import moment from "moment";
import { trackEvent } from "helpers/analytics";

function PatientBioForDerm() {
  const dispatch = useDispatch();
  const formRef = useRef();

  const nextAnimationRef = useRef();
  const { patientsList, incompleteData, subSteps, currentScreen } =
    useDermatologyVisitFlow();
  const { getCurrentPatient, getPatients, fetchingPatients } = usePatients();
  const { rightButtonClickRef, setLoading } = useVisitStepChange();
  rightButtonClickRef.current = onRightButtonClick;
  const currentPatient = getCurrentPatient(
    incompleteData?.patient_id,
    patientsList,
    true
  );
  const { userId } = useCurrentUser();

  const {
    mutate: updateBasicIfo,
    payload: userPayload,
    loading: updatingBasicDetails,
  } = useApiMutation(updateBasicInfoAction);

  const { updateVisit } = useUpdateDermatologyVisit();
  // DOB Confirm Changes
  const dobInputRef = useRef();
  const [showDate, setShowDate] = useState("");
  const [openCalendar, setOpenCalendar] = useState(false);
  const patientType =
    currentPatient?.relationship_with_account === "pappa" ? "young" : "old";
  useEffect(() => {
    if (userPayload) {
      getPatients();
    }
  }, [userPayload, getPatients]);

  function dispatchValidation(isValid) {
    if (subSteps[currentScreen]?.isValid !== isValid) {
      dispatch(
        dermaVisitFlowReducers.patient_bio({
          isValid: isValid,
        })
      );
    }
  }
  async function onRightButtonClick(_, nextAnimation) {
    nextAnimationRef.current = nextAnimation;
    // if (checkPatientBioAvailable({ patient: currentPatient })) {
    await formRef?.current?.handleSubmit(nextAnimation);
    // }
  }
  async function onFormSubmit(values) {
    setLoading(true);
    let payload = {
      id: userId,
      user: {
        patients_attributes: [
          {
            ...currentPatient,
            dob: moment(values.dob, "YYYY-MM-DD").format("YYYY/MM/DD"),
            gender: values.gender.toLowerCase(),
            height: getFeetInInches({
              feet: values.heightFeet,
              inches: values.heightInches,
            }),
            height_metric: 1,
            weight: values.weight ? Number(values?.weight) : null,
            weight_metric: 0,
          },
        ],
      },
    };

    payload?.user?.patients_attributes?.forEach((patient) => {
      delete patient["allergies"];
      delete patient["medications"];
      delete patient["skin_care_products"];
    });

    try {
      await updateBasicIfo({ userId, payload });
      if (!updatingBasicDetails && !fetchingPatients) {
        updateVisit({
          payload: {
            case: {
              id: incompleteData?.id,
              dermatology_steps: incompleteData?.dermatology_steps,
            },
          },
          change_current_screen: true,
          screen_to_complete: dermatology_sub_steps.patient_bio,
          callBack: () => {
            // dispatch(dermaVisitFlowReducers.patient_bio({}));
            nextAnimationRef?.current();
          },
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert("Something went wrong. Please try again later.");
    }
  }
  return (
    <StepScreenContainer align="start" childClass={"wrapper"}>
      <MedicalAssistant
        content={
          <p>
            Thank you, {currentPatient?.first_name}. To ensure that we meet the
            necessary medical requirements, please provide the following
            essential information for your medical chart with our practice.
            Kindly fill your bio details with care (Member DOB, Height, Weight,
            Gender at Birth).
          </p>
        }
      />
      <Formik
        innerRef={formRef}
        initialValues={{
          dob: currentPatient?.dob ?? "",
          type: patientType,
          gender: currentPatient?.gender
            ? currentPatient?.gender.charAt(0).toUpperCase() +
              currentPatient?.gender.slice(1)
            : "",
          heightFeet: currentPatient?.height
            ? getInchesInFeet({ height: currentPatient?.height }).feet
            : "",
          heightInches: currentPatient?.height
            ? getInchesInFeet({ height: currentPatient?.height }).inches
            : "",
          weight: currentPatient?.weight ?? "",
        }}
        validationSchema={updateBasicInfoPBSchema}
        onSubmit={onFormSubmit}
      >
        {({ setFieldValue, setFieldTouched, values, dirty, isValid }) => {
          setTimeout(() => dispatchValidation(isValid), 0);
          return (
            <Form className="flex flex-col pt-8 pb-2.5 pl-8 pr-8 mt-8 space-y-8 bg-white rounded-2xl">
              {/* DOB Confirm Changes */}
              <BirthInput
                ref={dobInputRef}
                disabled={currentPatient?.dob}
                name="dob"
                label="Date of Birth"
                id="dob"
                info={
                  patientType === "old"
                    ? "Age must be greater than or equal to 18"
                    : "Age must be under 18"
                }
                required
                max={moment().format("YYYY-MM-DD")}
                isOpen={openCalendar}
                open={() => setOpenCalendar(true)}
                close={() => setOpenCalendar(false)}
                onBlur={() => {
                  if (dirty) {
                    setFieldTouched("dob", true);
                  }
                }}
                onChange={(event) => {
                  const date = event.target.value
                    ? moment(event.target.value).format("YYYY-MM-DD")
                    : "";
                  setFieldValue("dob", date);

                  if (date !== showDate) {
                    setShowDate(event.target.value ?? "");
                  }
                }}
              />
              <div className="flex flex-wrap items-start">
                <div className="flex items-end flex-1 space-x-0 md:space-x-4 md:flex-initial">
                  <div className="flex-1 md:flex-initial">
                    <Input
                      type="unit"
                      style={{ width: "10ch", paddingRight: 4 }}
                      label="Height"
                      id="heightFeet"
                      name="heightFeet"
                      unit="ft"
                      min="1"
                      required
                      max="9"
                      customonblur={() => {
                        if (values?.heightFeet) {
                          setFieldValue(
                            "heightFeet",
                            parseInt(values?.heightFeet)
                          );
                        }
                      }}
                    />
                  </div>
                  <div className="flex-1 md:flex-initial">
                    <Input
                      type="unit"
                      style={{ width: "10ch", paddingRight: 4 }}
                      id="heightInches"
                      name="heightInches"
                      unit="in"
                      min="0"
                      max="11"
                      customonblur={() => {
                        if (values.heightInches !== "") {
                          setFieldValue(
                            "heightInches",
                            parseInt(
                              values.heightInches !== undefined
                                ? values.heightInches
                                : "0"
                            )
                          );
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="flex-1 md:ml-4 md:flex-initial">
                  <Input
                    style={{ width: "10ch", paddingRight: 4 }}
                    type="unit"
                    label="Weight"
                    id="weight"
                    name="weight"
                    min="1"
                    unit="lbs"
                    required
                    customonblur={() => {
                      if (values?.weight) {
                        setFieldValue(
                          "weight",
                          decimalRestriction({
                            number: Number(values?.weight),
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="flex items-baseline justify-between pt-8 md:pl-8 md:pt-0">
                  <div className="flex flex-col flex-1 mb-8 space-y-2">
                    <RegularRadioSelect
                      options={["Male", "Female"]}
                      selected={values?.gender}
                      vertical={false}
                      required
                      setSelected={(el) => {
                        setFieldValue("gender", el);
                        trackEvent(`gender_selected_${el?.toLowerCase()}`);
                      }}
                    />
                    <ErrorMessage
                      name="gender"
                      className="mt-2 text-base font-bold text-red"
                      component="p"
                    />
                    <FormikErrorFocus
                      align={"bottom"}
                      ease={"linear"}
                      duration={500}
                    />
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </StepScreenContainer>
  );
}

export default PatientBioForDerm;
