import React, { useState, useEffect } from "react";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import HealthProfileQuestion from "../../HealthProfileQuestion";
import HealthProfileRadio from "../../HealthProfileRadio";
import Spacer from "components/Spacer/Spacer";
import { useHistory, useParams } from "react-router-dom";
import useHealthProfile from "hooks/useHealthProfile";
import CSSLoader from "components/cssLoader/CSSLoader";
import SimpleHeader from "components/header/SimpleHeader";

const MARITAL_STATUSES = ["Married", "Single", "Divorced", "Widowed"];

function MaritalStatus() {
  const history = useHistory();
  const { health_id } = useParams();

  const {
    healthPayload,
    postHealthProfile,
    postingHealthProfile,
  } = useHealthProfile();

  const [maritalStatus, setMaritalStatus] = useState("");

  useEffect(() => {
    if (healthPayload?.health_profile) {
      setMaritalStatus(healthPayload?.health_profile?.marital_status);
    }
  }, [healthPayload]);

  function completed() {
    history.goBack();
  }

  function onSave() {
    let payload = {
      marital_status: maritalStatus,
    };
    postHealthProfile({ healthId: health_id, payload, completed });
  }

  return (
    <div className="h-screen bg-veryLightBlue">
      <SimpleHeader
        showBackButton
        title="Marital Status"
        rightActionView={
          <button
            disabled={postingHealthProfile}
            onClick={onSave}
            type={"button"}
            className="text-2xl font-bold text-orange"
          >
            {postingHealthProfile ? (
              <CSSLoader className="w-8 h-8" color="orange" />
            ) : (
              "Save"
            )}
          </button>
        }
      />
      <div className="p-12 bg-white h-eliminateHeaderWithBottomTab md:h-eliminateHeader">
        <MedicalAssistant content={<p>What is your marital status?</p>} />
        <Spacer height={26} />
        <HealthProfileQuestion text={"What is your marital status?"} />
        <div className={`flex items-center mt-8 flex-wrap`}>
          {MARITAL_STATUSES?.map((status, idx) => {
            return (
              <div className="flex flex-1 mb-8 mr-6" key={idx}>
                <HealthProfileRadio
                  text={status}
                  selected={maritalStatus === status}
                  onClick={() => setMaritalStatus(status)}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default MaritalStatus;
