import { step_status } from "./enum";

export function setPedStepStatus({ result_payload }) {
  let resultDuplicated = {
    ...result_payload,
    case: {
      ...result_payload?.case,
    },
  };
  return resultDuplicated;
}

export function checkAlreadyCompleted({
  screen_name,
  pediatric_steps,
  step_placement,
}) {
  if (
    pediatric_steps?.[step_placement]?.[screen_name] &&
    pediatric_steps?.[step_placement]?.[screen_name] === step_status.completed
  ) {
    return step_status.completed;
  } else {
    return step_status.current;
  }
}

export function setNextScreenToUpcoming({ state, status, nextScreen }) {
  if (
    status === step_status.completed &&
    state.subSteps[nextScreen].status === step_status.upcoming
  ) {
    state.subSteps[nextScreen].status = step_status.current;
  } else {
    return;
  }
}
