import React from "react";
import { Route, Switch, useRouteMatch, useParams } from "react-router-dom";
import AlcoholUse from "./alcoholUse/AlcoholUse";
import CaffeineUse from "./caffeineUse/CaffeineUse";
import Exercise from "./exercise/Exercise";
import HPSocialHistoryView from "./HPSocialHistoryView";
import MaritalStatus from "./maritalStatus/MaritalStatus";
import RecreationalDrugs from "./recreationalDrugs/RecreationalDrugs";
import SexualActivity from "./sexualActivity/SexualActivity";
import SpecialDiet from "./specialDiet/SpecialDiet";
import TobaccoUse from "./tobaccoUse/TobaccoUse";
import Vaccinations from "./vaccinations/Vaccinations";

function HPSocialHistory() {
  const { url } = useRouteMatch();
  const { health_id } = useParams()

  const urlSplit = url.split(health_id);
  const newUrl = `${urlSplit[0]}:health_id${urlSplit[1]}`;

  return (
    <Switch>
      <Route path={`${newUrl}/marital-status`}>
        <MaritalStatus />
      </Route>
      <Route path={`${newUrl}/alcohol-use`}>
        <AlcoholUse />
      </Route>
      <Route path={`${newUrl}/tobacco-use`}>
        <TobaccoUse />
      </Route>
      <Route path={`${newUrl}/exercise`}>
        <Exercise />
      </Route>
      <Route path={`${newUrl}/recreational-drugs`}>
        <RecreationalDrugs />
      </Route>
      <Route path={`${newUrl}/sexual-activity`}>
        <SexualActivity />
      </Route>
      <Route path={`${newUrl}/caffeine-use`}>
        <CaffeineUse />
      </Route>
      <Route path={`${newUrl}/special-diet`}>
        <SpecialDiet />
      </Route>
      <Route path={`${newUrl}/vaccinations`}>
        <Vaccinations />
      </Route>
      <Route path={`${newUrl}`}>
        <HPSocialHistoryView />
      </Route>
    </Switch>
  );
}

export default HPSocialHistory;
