import React, { useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import useHealthProfile from "hooks/useHealthProfile";
import CSSLoader from "components/cssLoader/CSSLoader";
import { Form, Formik } from "formik";
import HealthProfileQuestion from "../../HealthProfileQuestion";
import Spacer from "components/Spacer/Spacer";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import HealthProfileRadio from "../../HealthProfileRadio";
import Input from "components/inputs/Input";
import { HealthProfileSpecialDietSchema } from "helpers/validationSchema";
import FormikErrorFocus from "formik-error-focus";
import SimpleHeader from "components/header/SimpleHeader";

const YES_NO = ["Yes", "No"];

function SpecialDiet() {
  const formRef = useRef();
  const history = useHistory();
  const { health_id } = useParams();

  const {
    healthPayload,
    postHealthProfile,
    postingHealthProfile,
  } = useHealthProfile();

  useEffect(() => {
    if (
      healthPayload?.health_profile &&
      healthPayload?.health_profile?.special_diet
    ) {
      formRef?.current?.setValues({
        follow_special_diet:
          healthPayload?.health_profile?.special_diet?.on_diet ?? "",
        kind_of_special_diet:
          healthPayload?.health_profile?.special_diet?.diet_type ?? "",
      });
    }
  }, [healthPayload]);

  function completed() {
    history.goBack();
  }

  function onSave(values) {
    let payload = {
      special_diet: {
        on_diet: values?.follow_special_diet,
        diet_type: values?.kind_of_special_diet,
      },
    };
    postHealthProfile({ healthId: health_id, payload, completed });
  }
  return (
    <div className="h-screen bg-veryLightBlue">
      <Formik
        innerRef={formRef}
        onSubmit={onSave}
        initialValues={{
          follow_special_diet: "",
          kind_of_special_diet: "",
        }}
        validationSchema={HealthProfileSpecialDietSchema}
      >
        {({ values, submitCount, errors, setFieldValue, setValues }) => (
          <Form className="h-full">
            <SimpleHeader
              showBackButton
              title="Special Diet"
              rightActionView={
                <button
                  disabled={postingHealthProfile}
                  type={"submit"}
                  className="text-2xl font-bold text-orange"
                >
                  {postingHealthProfile ? (
                    <CSSLoader className="w-8 h-8" color="orange" />
                  ) : (
                    "Save"
                  )}
                </button>
              }
            />
            <div className="p-12 overflow-y-auto bg-white h-eliminateHeaderWithBottomTab md:h-eliminateHeader">
              <MedicalAssistant
                content={<p>Please tell us about your food intake.</p>}
              />
              <Spacer height={26} />
              <HealthProfileQuestion text={"Do you follow any special diet?"} />
              <div className={`flex items-center mt-8 flex-wrap`}>
                {YES_NO?.map((option, idx) => {
                  return (
                    <div className="flex flex-1 mb-8 mr-6" key={idx}>
                      <HealthProfileRadio
                        text={option}
                        selected={values?.follow_special_diet === option}
                        onClick={() => {
                          if (option === "No") {
                            setValues({
                              follow_special_diet: option,
                              kind_of_special_diet: "",
                            });
                          } else {
                            setFieldValue("follow_special_diet", option);
                          }
                        }}
                      />
                    </div>
                  );
                })}
              </div>
              {submitCount && errors?.follow_special_diet ? (
                <p className="mt-4 text-base font-bold text-red">
                  {errors?.follow_special_diet}
                </p>
              ) : null}
              {values?.follow_special_diet?.length &&
              values?.follow_special_diet === "Yes" ? (
                <>
                  <Spacer height={26} />
                  <Input
                    labelstyles={{
                      fontWeight: "500",
                      textOpacity: 1,
                      color: "rgba(16, 19, 71, 1)",
                    }}
                    label="What kind of special diet (vegan, high protein, etc..)?"
                    id="kind_of_special_diet"
                    name="kind_of_special_diet"
                  />
                </>
              ) : null}
            </div>
            <FormikErrorFocus align={"bottom"} ease={"linear"} duration={500} />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default SpecialDiet;
