import React, { useEffect, useRef, useMemo } from "react";
import useHealthProfile from "hooks/useHealthProfile";
import { useHistory, useParams } from "react-router-dom";
import CSSLoader from "components/cssLoader/CSSLoader";
import Spacer from "components/Spacer/Spacer";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import HealthProfileQuestion from "../HealthProfileQuestion";
import HealthProfileRadio from "../HealthProfileRadio";
import { Form, Formik } from "formik";
import Input from "components/inputs/Input";
import {
  HealthProfileFemaleHistorySchema,
  HealthProfileFemaleHistorySchemaForAdult,
  isAdult,
} from "helpers/validationSchema";
import moment from "moment";
import FormikErrorFocus from "formik-error-focus";
import { input_types } from "helpers/enum";
import SimpleHeader from "components/header/SimpleHeader";

const otherOptions = [
  "D&C",
  "Cesarean Section",
  "Tubal ligation",
  "Total hysterectomy",
  "Partial hysterectomy",
  "Breast surgery",
  "Other",
];

function HPFemaleHealth() {
  const formRef = useRef();
  const history = useHistory();
  const { health_id } = useParams();
  const {
    healthPayload,
    postHealthProfile,
    postingHealthProfile,
  } = useHealthProfile();

  useEffect(() => {
    if (healthPayload) {
      const female_history_from_api =
        healthPayload?.health_profile?.female_history;
      formRef?.current?.setValues({
        first_day_of_lmp: female_history_from_api?.lmp ?? "",
        menopause: female_history_from_api?.menopause ?? "",
        menopause_age: female_history_from_api?.menopause_age ?? "",
        menstrual_problem: female_history_from_api?.menstrual_problem ?? "",
        period: female_history_from_api?.period ?? "",
        pap_date: female_history_from_api?.pap_date ?? "",
        abnormal_pap: female_history_from_api?.abnormal_pap ?? "",
        abnormal_pap_date: female_history_from_api?.abnormal_pap_date ?? "",
        no_of_pregnancies: female_history_from_api?.no_of_pregnancies ?? "",
        no_of_births: female_history_from_api?.no_of_births ?? "",
        no_of_miscarriages: female_history_from_api?.no_of_miscarriages ?? "",
        mammogram_date: female_history_from_api?.date_of_last_mammogram ?? "",
        other: female_history_from_api?.other?.length
          ? female_history_from_api?.other?.map((el) => {
              if (el?.includes("Other")) {
                return "Other";
              } else {
                return el;
              }
            })
          : [],
        other_value:
          female_history_from_api?.other?.length &&
          female_history_from_api?.other?.find((el) => el?.includes("Other"))
            ? female_history_from_api?.other
                ?.find((el) => el?.includes("Other"))
                ?.split(" : ")?.[1]
            : "",
      });
    }
  }, [healthPayload]);

  function completed() {
    history.goBack();
  }

  function onSave(values) {
    let payload = {
      female_history: {
        lmp: values?.first_day_of_lmp,
        menopause: values?.menopause,
        menopause_age: values?.menopause_age,
        menstrual_problem: values?.menstrual_problem,
        period: values?.period,
        pap_date: values?.pap_date,
        abnormal_pap: values?.abnormal_pap,
        abnormal_pap_date: values?.abnormal_pap_date,
        no_of_pregnancies: values?.no_of_pregnancies,
        no_of_births: values?.no_of_births,
        no_of_miscarriages: values?.no_of_miscarriages,
        date_of_last_mammogram: values?.mammogram_date,
        other: values?.other_value?.length
          ? values?.other?.map((el) => {
              if (el?.includes("Other")) {
                return `Other : ${values?.other_value}`;
              } else {
                return el;
              }
            })
          : values?.other,
      },
    };
    postHealthProfile({ healthId: health_id, payload, completed });
  }

  const isAdultValue = useMemo(
    () =>
      healthPayload?.dob
        ? isAdult(moment(healthPayload?.dob, "YYYY-MM-DD"))
        : false,
    [healthPayload]
  );

  return (
    <div className="h-screen bg-veryLightBlue">
      <Formik
        onSubmit={onSave}
        innerRef={formRef}
        initialValues={{
          first_day_of_lmp: "",
          menopause: "",
          menopause_age: "",
          menstrual_problem: "",
          period: "",
          pap_date: "",
          abnormal_pap: "",
          abnormal_pap_date: "",
          no_of_pregnancies: "",
          no_of_births: "",
          no_of_miscarriages: "",
          mammogram_date: "",
          other: [],
          other_value: "",
        }}
        validationSchema={
          isAdultValue
            ? HealthProfileFemaleHistorySchemaForAdult
            : HealthProfileFemaleHistorySchema
        }
      >
        {({ setFieldValue, values, errors, submitCount }) => (
          <Form className="h-full">
            <SimpleHeader
              showBackButton
              title="Female Health"
              rightActionView={
                <button
                  disabled={postingHealthProfile}
                  type={"submit"}
                  className="text-2xl font-bold text-orange"
                >
                  {postingHealthProfile ? (
                    <CSSLoader className="w-8 h-8" color="orange" />
                  ) : (
                    "Save"
                  )}
                </button>
              }
            />
            <div className="px-12 py-6 pb-20 pr-20 overflow-y-auto bg-white h-eliminateHeaderWithBottomTab md:h-eliminateHeader">
              <MedicalAssistant
                content={<p>Please provide answers to the items below.</p>}
              />
              <Spacer height={26} />
              <div
                className={`${
                  values?.menopause?.length ? "opacity-50" : "opacity-100"
                }`}
              >
                <Input
                  disabled={values?.menopause?.length}
                  type={input_types.DATEPICKER}
                  label="First day of LMP"
                  id="first_day_of_lmp"
                  name="first_day_of_lmp"
                  onChange={(e) => {
                    if (e?.target?.value?.length) {
                      setFieldValue("menopause", "");
                    }
                    setFieldValue("first_day_of_lmp", e?.target?.value);
                  }}
                />
              </div>
              <Spacer height={20} />
              <p className="text-2xl font-medium">(OR)</p>
              <Spacer height={20} />
              <div
                className={`${
                  values?.first_day_of_lmp?.length
                    ? "opacity-50"
                    : "opacity-100"
                }`}
              >
                <HealthProfileQuestion text={"Menopause"} />
                <div className={`flex items-center mt-8 `}>
                  <div className="flex flex-1">
                    <HealthProfileRadio
                      disabled={values?.first_day_of_lmp?.length}
                      text={"Surgical"}
                      selected={values?.menopause === "Surgical" ? true : false}
                      onClick={() => {
                        setFieldValue("first_day_of_lmp", "");
                        setFieldValue("menopause", "Surgical");
                      }}
                    />
                  </div>
                  <div className="flex flex-1">
                    <HealthProfileRadio
                      disabled={values?.first_day_of_lmp?.length}
                      text={"Natural"}
                      selected={values?.menopause === "Natural" ? true : false}
                      onClick={() => {
                        setFieldValue("first_day_of_lmp", "");
                        setFieldValue("menopause", "Natural");
                      }}
                    />
                  </div>
                </div>
              </div>
              {submitCount && errors?.menopause ? (
                <p className="mt-4 text-base font-bold text-red">
                  {errors?.menopause}
                </p>
              ) : null}
              {values?.menopause?.length ? (
                <>
                  <Spacer height={26} />
                  <Input
                    type="number"
                    label="Menopause age (Required*)"
                    id="menopause_age"
                    name="menopause_age"
                  />
                </>
              ) : null}
              <Spacer height={26} />
              <HealthProfileQuestion text={"Any menstrual problems?"} />
              <div className="flex items-center mt-8">
                <div className="flex flex-1">
                  <HealthProfileRadio
                    text={"Yes"}
                    selected={
                      values?.menstrual_problem === "Yes" ? true : false
                    }
                    onClick={() => setFieldValue("menstrual_problem", "Yes")}
                  />
                </div>
                <div className="flex flex-1">
                  <HealthProfileRadio
                    text={"No"}
                    selected={values?.menstrual_problem === "No" ? true : false}
                    onClick={() => {
                      setFieldValue("menstrual_problem", "No");
                      setFieldValue("period", "");
                    }}
                  />
                </div>
              </div>
              {values?.menstrual_problem === "Yes" ? (
                <>
                  <Spacer height={26} />
                  <div className="flex flex-row items-end">
                    <Input
                      type="number"
                      label="Period every (Required*)"
                      id="period"
                      name="period"
                    />
                    <p className="pl-3 text-2xl font-medium">days</p>
                  </div>
                </>
              ) : null}
              <Spacer height={26} />
              <Input
                type={input_types.DATEPICKER}
                label="Last PAP (Date)?"
                id="pap_date"
                name="pap_date"
              />

              <Spacer height={26} />
              <HealthProfileQuestion text={"Any abnormal PAPs?"} />
              <div className="flex items-center mt-8">
                <div className="flex flex-1">
                  <HealthProfileRadio
                    text={"Yes"}
                    selected={values?.abnormal_pap === "Yes" ? true : false}
                    onClick={() => setFieldValue("abnormal_pap", "Yes")}
                  />
                </div>
                <div className="flex flex-1">
                  <HealthProfileRadio
                    text={"No"}
                    selected={values?.abnormal_pap === "No" ? true : false}
                    onClick={() => setFieldValue("abnormal_pap", "No")}
                  />
                </div>
              </div>
              {values?.abnormal_pap === "Yes" ? (
                <>
                  <Spacer height={26} />
                  <Input
                    type={input_types.DATEPICKER}
                    label="Abnormal PAP Date (Required*)?"
                    id="abnormal_pap_date"
                    name="abnormal_pap_date"
                  />
                </>
              ) : null}
              <Spacer height={26} />
              <Input
                type="number"
                label="Number of pregnancies"
                id="no_of_pregnancies"
                name="no_of_pregnancies"
              />
              <Spacer height={26} />
              <Input
                type="number"
                label="Number of births"
                id="no_of_births"
                name="no_of_births"
              />
              <Spacer height={26} />
              <Input
                type="number"
                label="Number of miscarriages"
                id="no_of_miscarriages"
                name="no_of_miscarriages"
              />
              <Spacer height={26} />
              <Input
                type={input_types.DATEPICKER}
                label="Date of last mammogram"
                id="mammogram_date"
                name="mammogram_date"
              />
              <Spacer height={26} />
              <HealthProfileQuestion text={"Check if you have had"} />
              <div className="flex flex-row flex-wrap mt-8">
                {otherOptions?.map((el, idx) => {
                  return (
                    <div key={idx} className="mb-8 mr-6">
                      <HealthProfileRadio
                        text={
                          el === "Other" && values?.other_value?.length
                            ? `${el} : ${values?.other_value}`
                            : el
                        }
                        selected={values?.other?.includes(el) ? true : false}
                        onClick={() => {
                          if (values?.other?.includes(el)) {
                            if (el === "Other") {
                              setFieldValue("other_value", "");
                            }
                            setFieldValue(
                              "other",
                              values?.other?.filter((elem) => elem !== el)
                            );
                          } else {
                            if (el === "Other") {
                              let other_value = prompt(
                                "Please enter the other value",
                                ""
                              );
                              if (other_value?.length) {
                                setFieldValue("other_value", other_value);
                                setFieldValue(
                                  "other",
                                  values?.other?.length
                                    ? [...values?.other, el]
                                    : [el]
                                );
                              }
                            } else {
                              setFieldValue(
                                "other",
                                values?.other?.length
                                  ? [...values?.other, el]
                                  : [el]
                              );
                            }
                          }
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <FormikErrorFocus align={"bottom"} ease={"linear"} duration={500} />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default HPFemaleHealth;
