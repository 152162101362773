import React, { useEffect, useRef, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import useHealthProfile from "hooks/useHealthProfile";
import CSSLoader from "components/cssLoader/CSSLoader";
import { Form, Formik } from "formik";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import Spacer from "components/Spacer/Spacer";
import Input from "components/inputs/Input";
import moment from "moment";
import { HealthProfileVaccinationSchema } from "helpers/validationSchema";
import FormikErrorFocus from "formik-error-focus";
import { input_types } from "helpers/enum";
import SimpleHeader from "components/header/SimpleHeader";

function Vaccinations() {
  const formRef = useRef();
  const history = useHistory();
  const { health_id } = useParams();

  const {
    healthPayload,
    postHealthProfile,
    postingHealthProfile,
  } = useHealthProfile();

  const find_date_from_key = useCallback(
    (key) =>
      healthPayload?.health_profile?.vaccines?.find((el) => el?.title === key)
        ?.date ?? "",
    [healthPayload?.health_profile?.vaccines]
  );

  useEffect(() => {
    if (
      healthPayload?.health_profile &&
      healthPayload?.health_profile?.vaccines
    ) {
      formRef?.current?.setValues({
        tdOrTdap: find_date_from_key("tdOrTdap"),
        flu: find_date_from_key("flu"),
        ppd: find_date_from_key("ppd"),
        mmr: find_date_from_key("mmr"),
        pneumococcal: find_date_from_key("pneumococcal"),
        hpv: find_date_from_key("hpv"),
      });
    }
  }, [healthPayload, find_date_from_key]);

  function completed() {
    history.goBack();
  }

  function onSave(values) {
    let payload = {
      vaccines: Object.keys(values).map((key) => ({
        title: key,
        date: values[key],
      })),
    };
    postHealthProfile({ healthId: health_id, payload, completed });
  }
  return (
    <div className="h-screen bg-veryLightBlue">
      <Formik
        innerRef={formRef}
        onSubmit={onSave}
        initialValues={{
          tdOrTdap: "",
          flu: "",
          ppd: "",
          mmr: "",
          pneumococcal: "",
          hpv: "",
        }}
        validationSchema={HealthProfileVaccinationSchema}
      >
        {() => (
          <Form className="h-full">
            <SimpleHeader
              showBackButton
              title="Vaccinations"
              rightActionView={
                <button
                  disabled={postingHealthProfile}
                  type={"submit"}
                  className="text-2xl font-bold text-orange"
                >
                  {postingHealthProfile ? (
                    <CSSLoader className="w-8 h-8" color="orange" />
                  ) : (
                    "Save"
                  )}
                </button>
              }
            />
            <div className="p-12 pr-20 overflow-y-auto bg-white h-eliminateHeaderWithBottomTab md:h-eliminateHeader">
              <MedicalAssistant
                content={
                  <p>
                    Please provide the approximate date you last received for
                    the following vaccinations.
                  </p>
                }
              />
              <div>
                <Spacer height={26} />
                <Input
                  labelstyles={{
                    fontWeight: "500",
                    textOpacity: 1,
                    color: "rgba(16, 19, 71, 1)",
                  }}
                  type={input_types.DATEPICKER}
                  label="Td / Tdap (Tetanus and Diphtheria)"
                  id="tdOrTdap"
                  name="tdOrTdap"
                  max={moment().format("YYYY-MM-DD")}
                />
                <Spacer height={26} />
                <Input
                  labelstyles={{
                    fontWeight: "500",
                    textOpacity: 1,
                    color: "rgba(16, 19, 71, 1)",
                  }}
                  type={input_types.DATEPICKER}
                  label="Influenza (Flu)"
                  id="flu"
                  name="flu"
                />
                <Spacer height={26} />
                <Input
                  labelstyles={{
                    fontWeight: "500",
                    textOpacity: 1,
                    color: "rgba(16, 19, 71, 1)",
                  }}
                  type={input_types.DATEPICKER}
                  label="PPD (Tuberculosis)"
                  id="ppd"
                  name="ppd"
                  max={moment().format("YYYY-MM-DD")}
                />
                <Spacer height={26} />
                <Input
                  labelstyles={{
                    fontWeight: "500",
                    textOpacity: 1,
                    color: "rgba(16, 19, 71, 1)",
                  }}
                  type={input_types.DATEPICKER}
                  label="MMR (Measles, Mumps and Rubella)"
                  id="mmr"
                  name="mmr"
                  max={moment().format("YYYY-MM-DD")}
                />
                <Spacer height={26} />
                <Input
                  labelstyles={{
                    fontWeight: "500",
                    textOpacity: 1,
                    color: "rgba(16, 19, 71, 1)",
                  }}
                  type={input_types.DATEPICKER}
                  label="Pneumococcal (PCV13, PPSV23)"
                  id="pneumococcal"
                  name="pneumococcal"
                  max={moment().format("YYYY-MM-DD")}
                />
                <Spacer height={26} />
                <Input
                  labelstyles={{
                    fontWeight: "500",
                    textOpacity: 1,
                    color: "rgba(16, 19, 71, 1)",
                  }}
                  type={input_types.DATEPICKER}
                  label="HPV (Human Papillomavirus)"
                  id="hpv"
                  name="hpv"
                  max={moment().format("YYYY-MM-DD")}
                />
              </div>
            </div>
            <FormikErrorFocus align={"bottom"} ease={"linear"} duration={500} />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Vaccinations;
