import React, { useEffect, useRef, useState } from "react";
import Images from "assets/images";
import BottomFixedButton from "components/bottomFixedButton/BottomFixedButton";
import Input from "components/inputs/Input";
import { Form, Formik } from "formik";
import { AddChildSchemaDerm } from "helpers/validationSchema";
import useToastify from "hooks/useToastify";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useApiMutation } from "hooks/useApiMutation";
import { addChild } from "api/actions/UserActions";
import usePatients from "hooks/visit/usePatients";
import LoaderComponent from "components/loader/LoaderComponent";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import SimpleHeader from "components/header/SimpleHeader";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

function AddChildModalDerm({ onClose, onComplete }) {
  const formRef = useRef();
  const { userId } = useCurrentUser();
  const { getPatients, patientsList, fetchingPatients } = usePatients();
  const { mutate: callAddChild, loading } = useApiMutation(addChild);
  const { showAlert } = useToastify();
  const [addedPatient, setAddedPatient] = useState("");

  useEffect(() => {
    if (patientsList) {
      onClose(addedPatient);
    }
  }, [patientsList, onClose, addedPatient]);

  async function addChildAPI(userId, payload) {
    try {
      const result = await callAddChild({ accountId: userId, payload });
      if (result.error || result.payload.error || result.payload.error_msg) {
        errorHandler(result.payload.error || result.payload.error_msg);
      } else {
        setAddedPatient(result?.payload?.status?.full_name);
        getPatients();
        if (onComplete) {
          onComplete();
        }
        showAlert("Child added successfully", "success");
      }
    } catch (error) {}
  }

  function errorHandler(message) {
    showAlert(message, "error");
  }

  function onFormSubmit(values) {
    const payload = {
      acc_id: userId,
      patient: {
        first_name: values?.first_name,
        last_name: values?.last_name,
      },
    };
    addChildAPI(userId, payload);
  }

  if (fetchingPatients || loading) {
    return (
      <div className="h-screen">
        <LoaderComponent
          showLoader={{
            state: true,
            message: loading ? "Adding Child" : "Please wait",
          }}
        />
      </div>
    );
  }

  return (
    <div className="h-screen bg-alternativeWhite">
      <SimpleHeader
        title="Add Child"
        wrapper
        rightActionView={
          <img
            onClick={onClose}
            className="w-10 cursor-pointer"
            src={Images.closeIcon}
            alt="close"
          />
        }
      />
      <div className="p-12 overflow-y-auto h-eliminateHeaderWithFooter bg-gradient-to-b from-blue-300 to-blue-500">
        <div className="space-y-12 wrapper">
          <MedicalAssistant
            content={
              <p>
                Please provide the below information for your medical chart with
                our practice.
              </p>
            }
          />
          <Formik
            innerRef={formRef}
            onSubmit={onFormSubmit}
            validationSchema={AddChildSchemaDerm}
            initialValues={{
              first_name: "",
              last_name: "",
            }}
          >
            {() => (
              <Form className="flex flex-col p-8 space-y-8 text-3xl bg-white rounded-2xl">
                <Input
                  id="first_name"
                  name="first_name"
                  label="First Name"
                  required
                />
                <Input
                  id="last_name"
                  name="last_name"
                  label="Last Name"
                  required
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <BottomFixedButton
        loading={loading}
        label="Save"
        type="submit"
        bgColor="bg-white"
        // disabled={isValid}
        onClick={() => formRef?.current?.handleSubmit()}
      />
    </div>
  );
}

export default AddChildModalDerm;
