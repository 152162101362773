import {
  availableProvidersAction,
  recommendProvidersAction,
  selectProvider,
} from "api/actions/UserActions";
import Images from "assets/images";
import BottomFixedButton from "components/bottomFixedButton/BottomFixedButton";
import DoctorCard from "components/doctorCard/DoctorCard";
import Header from "components/header/Header";
import LoaderComponent from "components/loader/LoaderComponent";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import Spacer from "components/Spacer/Spacer";
import { speciality } from "helpers/enum";
import { useApiMutation } from "hooks/useApiMutation";
import { useApiQuery } from "hooks/useApiQuery";
import { useCurrentUser } from "hooks/useCurrentUser";
import useModal from "hooks/useModal";
import useToastify from "hooks/useToastify";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { setUserDetails } from "reducers/currentUserReducer";
import DoctorProfileDetails from "../DoctorProfileDetails/DoctorProfileDetails";
import ProvidersList from "../ProvidersList/ProvidersList";
import Placeholder from "components/placeholders/Placeholder";

function SelectedPcProvider() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { showAlert } = useToastify();
  const { setShowModal } = useModal();
  const { userDetails } = useCurrentUser();

  const {
    payload: recommendedPcProviders,
    loading: gettingRPP,
    query: queryRecommendedPcProviders,
  } = useApiQuery(recommendProvidersAction(speciality.primary_care), false);
  const {
    payload: availablePcProviders,
    loading: gettingAPP,
    query: queryAvailablePcProviders,
  } = useApiQuery(availableProvidersAction(speciality.primary_care), false);

  const {
    mutate: callSelectProvider,
    loading: selectingProvider,
  } = useApiMutation(selectProvider);

  const [selectedPcProvider, setSelectedPcProvider] = useState(null);

  useEffect(() => {
    if (userDetails?.patient_insurance) {
      queryRecommendedPcProviders();
    } else {
      queryAvailablePcProviders();
    }
  }, [
    userDetails?.patient_insurance,
    queryAvailablePcProviders,
    queryRecommendedPcProviders,
  ]);

  useEffect(() => {
    if (recommendedPcProviders) {
      setSelectedPcProvider(recommendedPcProviders?.[0]);
    }
    if (availablePcProviders) {
      setSelectedPcProvider(availablePcProviders?.[0]);
    }
  }, [recommendedPcProviders, availablePcProviders]);

  async function onSubmit() {
    let payload = {
      postbody: {
        id: userDetails?.id,
        user: {},
      },
      userId: userDetails?.id,
    };

    if (selectedPcProvider?.id) {
      payload.postbody.user = {
        ...payload.postbody.user,
        practice_user_id: selectedPcProvider?.id,
      };
    }

    try {
      const result = await callSelectProvider(payload);
      if (result && result?.error === false) {
        dispatch(
          setUserDetails({
            userDetails: {
              ...userDetails,
              primary_care_physician: {
                provider_id: selectedPcProvider?.id,
                provider_photo_url: selectedPcProvider?.avatar,
                provider_name: selectedPcProvider?.name,
                first_name: selectedPcProvider?.name?.split(" ")?.[0],
                last_name: selectedPcProvider?.name?.split(" ")?.[1],
                licensed_states: selectedPcProvider?.licensed_states ?? [],
                active: true,
                license: selectedPcProvider?.license,
              },
            },
          })
        );
        history.replace({
          pathname: "/visit/primary-care",
        });
      } else {
        showAlert("Something went wrong", "error");
      }
    } catch (error) {
      console.log(error);
      showAlert("Something went wrong", "error");
    }
  }

  const doctorDetails = (data, onClose) => {
    return <DoctorProfileDetails details={data} onClose={onClose} />;
  };

  if (gettingRPP || gettingAPP) {
    return (
      <LoaderComponent
        showLoader={{
          state: true,
          message: "Retrieving providers based on your information",
        }}
      />
    );
  }

  if (selectedPcProvider?.name) {
    return (
      <div className="h-screen bg-veryLightBlue">
        <Header title={"My SkyMD Care Team"} />
        <div className="wrapper h-eliminateHeaderWithFooter overflow-y-auto bg-blue">
          <div className="p-72 px-12 pb-72 pt-8 min-h-full">
            <MedicalAssistant
              content={
                <p>
                  Your pre-selected <b>primary care physician</b> is{" "}
                  <b>{selectedPcProvider?.name}</b>, or you may select other
                  provider.
                </p>
              }
            />
            <div className="bg-white p-12 rounded-3xl mt-14">
              <div>
                <DoctorCard
                  imageUrl={selectedPcProvider?.avatar}
                  name={selectedPcProvider?.name}
                  speciality={selectedPcProvider?.speciality}
                  isActive={true}
                  license={selectedPcProvider?.license}
                />
                <Spacer height={19} />
                <div
                  className="cursor-pointer flex items-center justify-between px-0 py-8 border-t border-borderColor"
                  onClick={() => {
                    setShowModal({
                      state: true,
                      children: doctorDetails,
                      data: selectedPcProvider,
                    });
                  }}
                >
                  <p className="flex-grow font-light text-2xl pr-4 text-indigo">
                    View {selectedPcProvider?.name}'s Profile
                  </p>
                  <img
                    className="w-8"
                    src={Images.arrowRight}
                    alt="right_arrow"
                  />
                </div>
                <div
                  className="cursor-pointer flex items-center justify-between px-0 py-8 border-t  border-b border-borderColor"
                  onClick={() => {
                    setShowModal({
                      state: true,
                      children: (_, onClose) => (
                        <ProvidersList
                          onClose={onClose}
                          selectedProvider={selectedPcProvider}
                          providers={
                            recommendedPcProviders || availablePcProviders
                          }
                          onProviderChoose={(provider) => {
                            setSelectedPcProvider(provider);
                            onClose();
                          }}
                        />
                      ),
                    });
                  }}
                >
                  <p className="flex-grow font-light text-2xl pr-4 text-indigo">
                    Change {"Family Practice: Primary Care Physician"}
                  </p>
                  <img
                    className="w-8"
                    src={Images.arrowRight}
                    alt="right_arrow"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <BottomFixedButton
          label="Continue"
          onClick={selectedPcProvider?.id ? onSubmit : () => {}}
          disabled={selectedPcProvider?.id ? false : true}
          loading={selectingProvider || gettingRPP || gettingAPP}
        />
      </div>
    );
  } else {
    return (
      <div className="h-screen bg-veryLightBlue">
        <Header title={"My SkyMD Care Team"} />
        <div className="wrapper h-eliminateHeader overflow-y-auto bg-white">
          <Placeholder
            heading={`We are working hard to find a 
              Family Practice: Primary Care Physician who is licensed to provide services in ${userDetails?.city}.`}
            paragraph={
              "We will notify you when we have a provider available to service in your zipcode"
            }
            placeholderImage={Images.searchDoctor}
          />
        </div>
      </div>
    );
  }
}

export default SelectedPcProvider;
