import React, { useEffect, useRef, useCallback } from "react";
import useHealthProfile from "hooks/useHealthProfile";
import { useHistory, useParams } from "react-router-dom";
import CSSLoader from "components/cssLoader/CSSLoader";
import Spacer from "components/Spacer/Spacer";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import HealthProfileQuestion from "../HealthProfileQuestion";
import HealthProfileRadio from "../HealthProfileRadio";
import { Form, Formik } from "formik";
import { HealthProfileFamilyHistorySchema } from "helpers/validationSchema";
import Accordion from "components/accordion/Accordion";
import { familyHistoryAccordionData } from "./familyHistoryAccordionData";
import FormikErrorFocus from "formik-error-focus";
import SimpleHeader from "components/header/SimpleHeader";

const YESNO = ["Yes", "No"];

function HPFamilyHistory() {
  const formRef = useRef();
  const history = useHistory();
  const { health_id } = useParams();
  const {
    healthPayload,
    postHealthProfile,
    postingHealthProfile,
  } = useHealthProfile();

  const setTextValue = useCallback(
    ({ disease }) => {
      if (healthPayload?.health_profile?.family_history?.length) {
        const element = healthPayload?.health_profile?.family_history?.find(
          (el) => el?.disease?.toLowerCase()?.includes(disease)
        );
        if (element) {
          return element?.disease?.split(" - ")?.[1];
        } else {
          return "";
        }
      }
    },
    [healthPayload]
  );

  useEffect(() => {
    if (healthPayload && healthPayload?.health_profile) {
      formRef?.current?.setValues({
        is_adopted:
          healthPayload?.health_profile?.adopted === true
            ? "Yes"
            : healthPayload?.health_profile?.adopted === false
            ? "No"
            : "",
        data: healthPayload?.health_profile?.family_history?.length
          ? healthPayload?.health_profile?.family_history?.map((el) => {
              if (el?.disease?.toLowerCase()?.includes("cancer")) {
                return { ...el, disease: "Cancer" };
              }
              if (el?.disease?.toLowerCase()?.includes("other")) {
                return { ...el, disease: "Other" };
              }
              return el;
            })
          : [],
        cancer_type: setTextValue({ disease: "cancer" }),
        other_disease: setTextValue({ disease: "other" }),
      });
    }
  }, [healthPayload, setTextValue]);

  function completed() {
    history.goBack();
  }

  function onSave(values) {
    let payload = {
      adopted: values?.is_adopted === "Yes" ? true : false,
      family_history: values?.data
        ?.filter((elem) => elem?.members_affected?.length)
        ?.map((el) => {
          if (el?.disease === "Cancer") {
            return { ...el, disease: `Cancer - ${values?.cancer_type}` };
          }
          if (el?.disease === "Other") {
            return { ...el, disease: `Other - ${values?.other_disease}` };
          }
          return el;
        }),
    };

    postHealthProfile({ healthId: health_id, payload, completed });
  }

  return (
    <div className="h-screen bg-veryLightBlue">
      <Formik
        onSubmit={onSave}
        innerRef={formRef}
        initialValues={{
          is_adopted: "",
          cancer_type: "",
          other_disease: "",
          data: [],
        }}
        validationSchema={HealthProfileFamilyHistorySchema}
      >
        {({ setFieldValue, values, submitCount, errors }) => (
          <Form className="h-full">
            <SimpleHeader
              showBackButton
              title="Family History"
              rightActionView={
                <button
                  disabled={postingHealthProfile}
                  type={"submit"}
                  className="text-2xl font-bold text-orange"
                >
                  {postingHealthProfile ? (
                    <CSSLoader className="w-8 h-8" color="orange" />
                  ) : (
                    "Save"
                  )}
                </button>
              }
            />
            <div className="px-12 py-6 overflow-y-auto bg-white h-eliminateHeaderWithBottomTab md:h-eliminateHeader">
              <MedicalAssistant
                content={<p>Please tell us about your family history.</p>}
              />
              <Spacer height={26} />
              <HealthProfileQuestion text={"Are you adopted?"} />
              <div className="flex items-center mt-8">
                {YESNO?.map((option, optionIndex) => {
                  return (
                    <div className="flex flex-1" key={optionIndex}>
                      <HealthProfileRadio
                        text={option}
                        selected={values?.is_adopted === option ? true : false}
                        onClick={() => setFieldValue("is_adopted", option)}
                      />
                    </div>
                  );
                })}
              </div>
              {submitCount && errors?.is_adopted ? (
                <p className="mt-4 text-base font-bold text-red">
                  {errors?.is_adopted}
                </p>
              ) : null}
              {values?.is_adopted?.length ? (
                <>
                  <Spacer height={26} />
                  <HealthProfileQuestion
                    text={
                      values?.is_adopted === "Yes"
                        ? "Please tell us anything you know about your blood relatives' family history."
                        : "Does anyone in your family have the following diseases?"
                    }
                  />
                  <div className="my-14">
                    <Accordion
                      multiple
                      data={familyHistoryAccordionData({
                        setFieldValue,
                        family_history: values?.data,
                        cancer_type: values?.cancer_type,
                        other_disease: values?.other_disease,
                      })}
                    />
                  </div>
                </>
              ) : null}
            </div>
            <FormikErrorFocus align={"bottom"} ease={"linear"} duration={500} />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default HPFamilyHistory;
