import React from "react";
import usePatients from "hooks/visit/usePatients";
import { useEffect } from "react";
import LoaderComponent from "components/loader/LoaderComponent";
import Images from "assets/images";
import { useHistory, useRouteMatch } from "react-router-dom";
import { isAdult } from "helpers/validationSchema";
import moment from "moment";
import SimpleHeader from "components/header/SimpleHeader";

function HealthProfileList() {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { getPatients, patientsList, fetchingPatients } = usePatients();

  useEffect(() => {
    getPatients();
  }, [getPatients]);

  if (fetchingPatients) {
    return (
      <LoaderComponent
        showLoader={{ state: true, message: "Fetching patients list" }}
      />
    );
  }

  const myself = patientsList?.filter(
    (el) => el?.relationship_with_account === "myself"
  );
  const children = patientsList?.filter(
    (el) => el?.dob && isAdult(moment(el?.dob)) === false
  );
  const others = patientsList?.filter(
    (el) =>
      el?.dob &&
      isAdult(moment(el?.dob)) &&
      el?.relationship_with_account !== "myself"
  );

  const ListItem = ({ patient }) => (
    <div
      onClick={() => history.push(`${url}/${patient?.health_profile?.id}`)}
      className="flex items-center justify-between py-8 border-b cursor-pointer border-borderColor"
    >
      <div>
        <p className="text-3xl font-medium">{patient?.full_name}</p>
        <p
          className={`pt-2 text-xl font-light ${
            patient?.health_profile?.completed_perentage &&
            patient?.health_profile?.completed_perentage === 100
              ? "text-green"
              : "text-orange"
          }`}
        >
          {patient?.health_profile?.completed_perentage
            ? patient?.health_profile?.completed_perentage + "% completed"
            : "Incomplete"}
        </p>
      </div>
      <img src={Images.arrowRight} alt="right_arrow" className="w-8" />
    </div>
  );

  return (
    <div className="h-screen bg-veryLightBlue">
      <SimpleHeader
        onBackButtonClick={() => {
          history.goBack();
        }}
        className="mx-2"
        title="Select Patient"
      />
      <div className="px-12 pr-20 overflow-y-auto bg-white h-eliminateHeaderWithBottomTab md:h-eliminateHeader">
        {/* account holder */}
        {myself?.map((patient, index) => {
          return <ListItem key={index} patient={patient} />;
        })}

        {/* children */}
        {children?.length ? (
          <div className="mt-16">
            <h3 className="text-2xl font-black tracking-wide uppercase">
              Children
            </h3>
            {children?.map((patient, index) => {
              return <ListItem key={index} patient={patient} />;
            })}
          </div>
        ) : null}

        {/* Others */}
        {others?.length ? (
          <div className="mt-16">
            <h3 className="text-2xl font-black tracking-wide uppercase">
              Others
            </h3>
            {others?.map((patient, index) => {
              return <ListItem key={index} patient={patient} />;
            })}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default HealthProfileList;
