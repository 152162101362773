import React, { useEffect, useRef, useState } from "react";
import Images from "assets/images";
import BottomFixedButton from "components/bottomFixedButton/BottomFixedButton";
import Header from "components/header/Header";
import Input from "components/inputs/Input";
import RegularRadioSelect from "components/radioButtonCard/RegularRadioSelect";
import Spacer from "components/Spacer/Spacer";
import { Form, Formik } from "formik";
import { AddChildSchema } from "helpers/validationSchema";
import FormikErrorFocus from "formik-error-focus";
import useToastify from "hooks/useToastify";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useApiMutation } from "hooks/useApiMutation";
import { addChild } from "api/actions/UserActions";
import moment from "moment";
import usePatients from "hooks/visit/usePatients";
import LoaderComponent from "components/loader/LoaderComponent";
import { decimalRestriction, getFeetInInches } from "helpers/metricConversion";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import BirthInput from "components/inputs/BirthInput";

function AddChildModal({ onClose, onComplete }) {
  const formRef = useRef();
  const { userId } = useCurrentUser();
  const { getPatients, patientsList, fetchingPatients } = usePatients();
  const { mutate: callAddChild, loading } = useApiMutation(addChild);
  const { showAlert } = useToastify();

  // DOB Confirm Changes
  const dobInputRef = useRef();
  const [showDate, setShowDate] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);

  // Weight Confirm Changes
  const [showWeight, setShowWeight] = useState("");
  const [showWeightConfirm, setShowWeightConfirm] = useState(false);

  // Height Confirm Changes
  const [showHeight, setShowHeight] = useState("");
  const [showHeightConfirm, setShowHeightConfirm] = useState(false);

  useEffect(() => {
    if (patientsList) {
      onClose();
    }
  }, [patientsList, onClose]);

  async function addChildAPI(userId, payload) {
    try {
      const result = await callAddChild({ accountId: userId, payload });
      if (result.error || result.payload.error || result.payload.error_msg) {
        errorHandler(result.payload.error || result.payload.error_msg);
      } else {
        getPatients();
        if (onComplete) {
          onComplete();
        }
        showAlert("Child added successfully", "success");
      }
    } catch (error) {}
  }

  function errorHandler(message) {
    showAlert(message, "error");
  }

  function onFormSubmit(values) {
    const payload = {
      acc_id: userId,
      patient: {
        dob: moment(values?.dob, "YYYY-MM-DD").unix(),
        gender: values?.gender?.toLowerCase(),
        first_name: values?.first_name,
        last_name: values?.last_name,
        height: getFeetInInches({
          feet: values.heightFeet,
          inches: values.heightInches,
        }),
        height_metric: 1,
        weight: Number(values?.weight),
        weight_metric: 0,
      },
    };
    addChildAPI(userId, payload);
  }

  if (fetchingPatients || loading) {
    return (
      <div className="h-screen">
        <LoaderComponent
          showLoader={{
            state: true,
            message: loading ? "Adding Child" : "Please wait",
          }}
        />
      </div>
    );
  }

  return (
    <div className="h-screen bg-veryLightBlue">
      <Header
        hideBackBtn
        title="Add Child"
        rightActionView={
          <img
            onClick={onClose}
            className="w-10 cursor-pointer"
            src={Images.closeIcon}
            alt="close"
          />
        }
      />
      <div className="overflow-y-scroll wrapper h-eliminateHeaderWithFooter">
        <div className="min-h-full p-12 bg-blue">
          <MedicalAssistant
            content={
              <p>
                Please provide the below information for your medical chart with
                our practice.
              </p>
            }
          />
          <Spacer height="39" />
          <Formik
            innerRef={formRef}
            onSubmit={onFormSubmit}
            validationSchema={AddChildSchema}
            initialValues={{
              first_name: "",
              last_name: "",
              dob: "",
              gender: "",
              heightFeet: "",
              heightInches: "",
              weight: "",
            }}
          >
            {({ values, setFieldValue, setFieldTouched, dirty }) => (
              <Form className="p-12 text-3xl bg-white rounded-3xl">
                <Input id="first_name" name="first_name" label="First Name" />
                <Spacer height="32" />
                <Input id="last_name" name="last_name" label="Last Name" />
                <Spacer height="32" />
                {/* DOB Confirm Changes */}
                <BirthInput
                  ref={dobInputRef}
                  id="dob"
                  name="dob"
                  label="DOB"
                  max={moment().format("YYYY-MM-DD")}
                  isOpen={openCalendar}
                  open={() => setOpenCalendar(true)}
                  close={() => setOpenCalendar(false)}
                  onBlur={() => {
                    if (dirty) {
                      setFieldTouched("dob", true);
                      setShowConfirm(true);
                    }
                  }}
                  onChange={(event) => {
                    const date = event.target.value
                      ? moment(event.target.value).format("YYYY-MM-DD")
                      : "";
                    setFieldValue("dob", date);

                    if (date !== showDate) {
                      setShowDate(event.target.value ?? "");
                    }
                  }}
                />
                <Spacer height="32" />
                <div className="flex flex-wrap items-start">
                  <div className="flex items-end flex-1 space-x-4 md:flex-initial">
                    <div className="flex-1 md:flex-initial">
                      <Input
                        type="unit"
                        label="Height"
                        id="heightFeet"
                        style={{ width: "10ch", paddingRight: 4 }}
                        name="heightFeet"
                        unit="ft"
                        min="1"
                        max="9"
                        customonblur={() => {
                          if (values?.heightFeet) {
                            setShowHeight(
                              `${decimalRestriction({
                                number: Number(
                                  values.heightInches !== undefined &&
                                    values.heightInches > 0
                                    ? `${parseInt(
                                        values?.heightFeet
                                      )}.${parseInt(values?.heightInches)}`
                                    : parseInt(values?.heightFeet)
                                ),
                              })} ft`
                            );
                            // setShowHeightConfirm(true);
                            setFieldValue(
                              "heightFeet",
                              parseInt(values?.heightFeet)
                            );
                          }
                        }}
                      />
                    </div>
                    <div className="flex-1 md:flex-initial">
                      <Input
                        type="unit"
                        id="heightInches"
                        name="heightInches"
                        style={{ width: "10ch", paddingRight: 4 }}
                        unit="in"
                        min="0"
                        max="11"
                        onFocus={() => setShowHeightConfirm(false)}
                        customonblur={() => {
                          if (values.heightInches !== "") {
                            setShowHeight(
                              `${decimalRestriction({
                                number: Number(
                                  values.heightInches !== undefined &&
                                    values.heightInches > 0
                                    ? `${parseInt(
                                        values?.heightFeet
                                      )}.${parseInt(values?.heightInches)}`
                                    : parseInt(values?.heightFeet)
                                ),
                              })} ft`
                            );
                            // setShowHeightConfirm(true);
                            setFieldValue(
                              "heightInches",
                              parseInt(
                                values.heightInches !== undefined
                                  ? values.heightInches
                                  : "0"
                              )
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex-1 ml-4 md:flex-initial">
                    <Input
                      style={{ width: "10ch", paddingRight: 4 }}
                      type="unit"
                      label="Weight"
                      id="weight"
                      name="weight"
                      min="1"
                      unit="lbs"
                      customonblur={() => {
                        if (values?.weight) {
                          setShowWeight(
                            `${decimalRestriction({
                              number: Number(values?.weight),
                            })} lbs`
                          );
                          // setShowWeightConfirm(true);
                          setFieldValue(
                            "weight",
                            decimalRestriction({
                              number: Number(values?.weight),
                            })
                          );
                        }
                      }}
                    />
                  </div>
                  <div className="flex items-baseline justify-between ml-0 md:ml-4">
                    <div className="flex flex-col flex-1 space-y-2">
                      <RegularRadioSelect
                        options={["Male", "Female"]}
                        selected={values?.gender}
                        setSelected={(el) => setFieldValue("gender", el)}
                      />
                      <FormikErrorFocus
                        align={"bottom"}
                        ease={"linear"}
                        duration={500}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <Spacer height="39" />
        </div>
      </div>
      <BottomFixedButton
        loading={loading}
        label="Save"
        type="submit"
        onClick={() => formRef?.current?.handleSubmit()}
      />
      {/* DOB Confirm Changes */}
      {showConfirm ? (
        <div className="absolute top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-60">
          <div className="flex flex-col w-11/12 max-w-2xl p-8 space-y-8 bg-white">
            <p className="text-3xl font-bold">Confirm Date of Birth</p>
            <p className="text-2xl font-medium leading-9">
              You've entered {moment(showDate).format("MM-DD-YYYY")} as date of
              birth. Click CONFIRM to proceed or EDIT to change the date.
            </p>
            <div className="flex items-center justify-end space-x-4">
              <button
                className="p-4 text-xl font-bold uppercase rounded bg-opacity-10 text-indigo min-w-32"
                onClick={() => {
                  if (dobInputRef.current) {
                    dobInputRef.current.focus();
                  }
                  setOpenCalendar(true);
                  setShowConfirm(false);
                }}
              >
                Edit
              </button>
              <button
                className="flex items-center justify-center p-4 text-xl font-bold uppercase rounded bg-indigo bg-opacity-20 text-indigo min-w-32"
                onClick={() => {
                  setShowConfirm(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div />
      )}
      {/* Weight Confirm Changes */}
      {showWeightConfirm ? (
        <div className="absolute top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-60">
          <div className="flex flex-col w-11/12 max-w-2xl p-8 space-y-8 bg-white">
            <p className="text-3xl font-bold">Confirm Weight</p>
            <p className="text-2xl font-medium leading-9">
              You've entered <span className="font-bold">{showWeight}</span> as
              weight. Click CONFIRM to proceed or EDIT to change the weight.
            </p>
            <div className="flex items-center justify-end space-x-4">
              <button
                className="p-4 text-xl font-bold uppercase rounded bg-opacity-10 text-indigo min-w-32"
                onClick={() => {
                  setShowWeightConfirm(false);
                }}
              >
                Edit
              </button>
              <button
                className="flex items-center justify-center p-4 text-xl font-bold uppercase rounded bg-indigo bg-opacity-20 text-indigo min-w-32"
                onClick={() => {
                  setShowWeightConfirm(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div />
      )}
      {/* Height Confirm Changes */}
      {showHeightConfirm ? (
        <div className="absolute top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-60">
          <div className="flex flex-col w-11/12 max-w-2xl p-8 space-y-8 bg-white">
            <p className="text-3xl font-bold">Confirm Height</p>
            <p className="text-2xl font-medium leading-9">
              You've entered <span className="font-bold">{showHeight}</span> as
              height. Click CONFIRM to proceed or EDIT to change the height.
            </p>
            <div className="flex items-center justify-end space-x-4">
              <button
                className="p-4 text-xl font-bold uppercase rounded bg-opacity-10 text-indigo min-w-32"
                onClick={() => {
                  setShowHeightConfirm(false);
                }}
              >
                Edit
              </button>
              <button
                className="flex items-center justify-center p-4 text-xl font-bold uppercase rounded bg-indigo bg-opacity-20 text-indigo min-w-32"
                onClick={() => {
                  setShowHeightConfirm(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
}

export default AddChildModal;
