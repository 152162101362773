import { useCallback, useEffect } from "react";
import { getPatientsAction } from "api/actions/UserActions";
import { useApiQuery } from "hooks/useApiQuery";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useDispatch } from "react-redux";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import { speciality } from "helpers/enum";
import { primaryCareVisitFlowReducers } from "reducers/primary_care/primaryCareVisitFlowReducer";
import { pedVisitFlowReducers } from "reducers/ped/pedVisitFlowReducer";

function usePatients() {
  const dispatch = useDispatch();
  const { userId, currentSpeciality } = useCurrentUser();
  const {
    loading: fetchingPatients,
    payload: patientsList,
    query: getPatients,
  } = useApiQuery(getPatientsAction(userId), false);
  useEffect(() => {
    if (patientsList?.length) {
      if (currentSpeciality === speciality.dermatology) {
        dispatch(
          dermaVisitFlowReducers.patientsList({
            value: patientsList,
          })
        );
      } else if (currentSpeciality === speciality.primary_care) {
        dispatch(
          primaryCareVisitFlowReducers.patientsList({
            value: patientsList,
          })
        );
      } else if (currentSpeciality === speciality.ped) {
        dispatch(
          pedVisitFlowReducers.patientsList({
            value: patientsList,
          })
        );
      } else {
        return;
      }
    }
  }, [dispatch, patientsList, currentSpeciality]);

  function getAccountHolder(patients_list) {
    return patients_list?.find(
      (patient) => patient?.relationship_with_account === "myself"
    );
  }

  const getPatientById = useCallback((patient_id, patients_list) => {
    return patients_list?.find((patient) => patient?.id === patient_id);
  }, []);

  //To match with rails upgrade, we will send only required data if filter_data is true.
  const getCurrentPatient = useCallback(
    (patient_id, patients_list, filter_data = false) => {
      const patient = patients_list?.find(
        (patient) => patient?.id === patient_id
      );
      return patient && filter_data
        ? {
            relationship_with_account: patient.relationship_with_account,
            id: patient.id,
            dob: patient.dob,
            gender: patient.gender,
            first_name: patient.first_name,
            middle_name: patient.middle_name,
            last_name: patient.last_name,
            height: patient.height,
            height_metric: patient.height_metric,
            weight: patient.weight,
            weight_metric: patient.weight_metric,
            street_add_1: patient.street_add_1,
            street_add_2: patient.street_add_2,
            city: patient.city,
            state: patient.state,
            zip: patient.zip,
            allergies: patient.allergies,
            medications: patient.medications,
            skin_care_products: patient.skin_care_products,
            pcp_name: patient.pcp_name,
            pcp_fax: patient.pcp_fax,
          }
        : patient ?? null;
    },
    []
  );

  return {
    getPatients,
    getAccountHolder,
    patientsList,
    fetchingPatients,
    getCurrentPatient,
    getPatientById,
  };
}

export default usePatients;
