import React, { useEffect, useRef, useState } from "react";
import Images from "assets/images";
import BottomFixedButton from "components/bottomFixedButton/BottomFixedButton";
import Input from "components/inputs/Input";
import { Form, Formik } from "formik";
import { AddSomeoneElseSchemaDerm } from "helpers/validationSchema";
import { addChild, getGeoDataFromZipcodeAction } from "api/actions/UserActions";
import { useApiMutation } from "hooks/useApiMutation";
import { useParameterizedQuery } from "react-fetching-library";
import { useCurrentUser } from "hooks/useCurrentUser";
import { input_types, speciality } from "helpers/enum";
import useToastify from "hooks/useToastify";
import useDataFromUserDetails from "hooks/useDataFromUserDetails";
import usePatients from "hooks/visit/usePatients";
import LoaderComponent from "components/loader/LoaderComponent";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import SimpleHeader from "components/header/SimpleHeader";

function AddSomeoneElseModalDerm({ onClose, currentSpeciality }) {
  const formRef = useRef();
  const { userId } = useCurrentUser();
  const { showAlert } = useToastify();
  const { getPatients, patientsList, fetchingPatients } = usePatients();
  const { dermaDoctorDetails } = useDataFromUserDetails();
  const [addedPatient, setAddedPatient] = useState("");
  const {
    query: getZipcodeDetails,
    loading: gettingZipCode,
  } = useParameterizedQuery(getGeoDataFromZipcodeAction);
  const { mutate: callAddPatient, loading: addingPatient } = useApiMutation(
    addChild
  );

  useEffect(() => {
    if (patientsList) {
      onClose(addedPatient);
    }
  }, [patientsList, onClose, addedPatient]);

  async function addPatientAPI(userId, payload) {
    try {
      const result = await callAddPatient({ accountId: userId, payload });
      if (result.error || result.payload.error || result.payload.error_msg) {
        errorHandler(result.payload.error || result.payload.error_msg);
      } else {
        setAddedPatient(result?.payload?.status?.full_name);
        getPatients();
        showAlert("Patient added successfully", "success");
      }
    } catch (error) {}
  }

  function errorHandler(message) {
    showAlert(message, "error");
  }

  async function onFormSubmit(values) {
    let patient = {
      first_name: values.first_name,
      last_name: values.last_name,
      relationship_with_account: values.relationship
        .toLowerCase()
        .replace(" ", "_"),
      zip: values.zip_code,
    };
    try {
      const { payload: zipResult } = await getZipcodeDetails(values.zip_code);
      if (zipResult?.geo_data) {
        if (currentSpeciality === speciality.dermatology) {
          if (
            dermaDoctorDetails?.licensed_states?.includes(
              zipResult?.geo_data?.state_code
            )
          ) {
            addPatientAPI(userId, patient);
          } else {
            alert(
              "The state of residence is outside of the coverage area of this provider. Please change your zipcode and try again."
            );
          }
        } else if (currentSpeciality === speciality.primary_care) {
          addPatientAPI(userId, patient);
        }
      } else {
        alert("Invalid zipcode.");
      }
    } catch (error) {
      alert("Something went wrong. Please try again later.");
    }
  }

  if (gettingZipCode || addingPatient || fetchingPatients) {
    return (
      <div className="h-screen">
        <LoaderComponent
          showLoader={{
            state: true,
            message: "Adding Patient",
          }}
        />
      </div>
    );
  }

  return (
    <div className="h-screen bg-alternativeWhite">
      <SimpleHeader
        wrapper
        title="Add Patient"
        rightActionView={
          <img
            onClick={onClose}
            className="w-10 cursor-pointer"
            src={Images.closeIcon}
            alt="close"
          />
        }
      />
      <div className="p-12 overflow-y-auto h-eliminateHeaderWithFooter bg-gradient-to-b from-blue-300 to-blue-500">
        <div className="space-y-12 wrapper">
          <MedicalAssistant
            content={
              <p>
                Please provide the below information for your medical chart with
                our practice.
              </p>
            }
          />
          <Formik
            innerRef={formRef}
            onSubmit={onFormSubmit}
            validationSchema={AddSomeoneElseSchemaDerm}
            initialValues={{
              first_name: "",
              last_name: "",
              dob: "",
              gender: "",
              street_address_1: "",
              street_address_2: "",
              city: "",
              zip_code: "",
              relationship: "",
              heightFeet: "",
              heightInches: "",
              weight: "",
            }}
          >
            {() => (
              <Form className="p-8 space-y-8 bg-white rounded-2xl">
                <div className="flex flex-col space-x-0 space-y-8 md:flex-row md:space-x-4 md:space-y-0">
                  <div className="flex-1">
                    <Input
                      id="first_name"
                      name="first_name"
                      label="First Name"
                      required
                    />
                  </div>
                  <div className="flex-1">
                    <Input
                      id="last_name"
                      name="last_name"
                      label="Last Name"
                      required
                    />
                  </div>
                </div>
                <Input
                  default="Select relationship"
                  required
                  options={["Caregiver", "Healthcare Provider"]}
                  type={input_types.DROPDOWN}
                  id="relationship"
                  name="relationship"
                  label="Relationship of patient to member"
                />

                <div className="flex-1">
                  <Input
                    id="zip_code"
                    name="zip_code"
                    label="Zipcode"
                    required
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <BottomFixedButton
        label="Save"
        bgColor="bg-white"
        onClick={() => formRef?.current?.handleSubmit()}
        loading={gettingZipCode || addingPatient}
      />
    </div>
  );
}

export default AddSomeoneElseModalDerm;
