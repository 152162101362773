import CSSLoader from "components/cssLoader/CSSLoader";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import Spacer from "components/Spacer/Spacer";
import { Form, Formik } from "formik";
import useHealthProfile from "hooks/useHealthProfile";
import React, { useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import HealthProfileQuestion from "../HealthProfileQuestion";
import HealthProfileRadio from "../HealthProfileRadio";
import AddSurgeriesHospitalisations from "./AddSurgeriesHospitalisations";
import FormikErrorFocus from "formik-error-focus";
import { HealthProfileSurgeriesHospitalisationSchema } from "helpers/validationSchema";
import SimpleHeader from "components/header/SimpleHeader";

const YESNO = ["Yes", "No"];

function SurgeriesHospitalisations() {
  const formRef = useRef();
  const history = useHistory();
  const { health_id } = useParams();
  const {
    healthPayload,
    postHealthProfile,
    postingHealthProfile,
  } = useHealthProfile();

  useEffect(() => {
    if (healthPayload) {
      formRef?.current?.setValues({
        hospitalized_before:
          healthPayload?.health_profile?.hospitalized_before ===
          "is_hospitalized_before"
            ? "Yes"
            : healthPayload?.health_profile?.hospitalized_before ===
              "not_hospitalized_before"
            ? "No"
            : "",
        surgeriesHospitalisations:
          healthPayload?.health_profile?.hospitalizations?.map((el) => ({
            surgery_or_reason_for_hopitalisation:
              el?.surgeries_or_hospitalisations,
            date: el?.date,
          })),
      });
    }
  }, [healthPayload]);

  function completed() {
    history.goBack();
  }

  function onSave(values) {
    let payload = {};
    if (values?.hospitalized_before === "Yes") {
      payload = {
        hospitalized_before: 2,
        hospitalizations: values?.surgeriesHospitalisations?.map((el) => ({
          surgeries_or_hospitalisations:
            el?.surgery_or_reason_for_hopitalisation,
          date: el?.date,
        })),
      };
    } else {
      payload = {
        hospitalized_before: 1,
        hospitalizations: [],
      };
    }
    postHealthProfile({ healthId: health_id, payload, completed });
  }
  return (
    <div className="h-screen bg-veryLightBlue">
      <Formik
        onSubmit={onSave}
        innerRef={formRef}
        initialValues={{
          hospitalized_before: "",
          surgeriesHospitalisations: [],
        }}
        validationSchema={HealthProfileSurgeriesHospitalisationSchema}
      >
        {({ setFieldValue, values, errors, submitCount }) => (
          <Form className="h-full">
            <SimpleHeader
              showBackButton
              title="Surgeries / Hospitalizations"
              rightActionView={
                <button
                  disabled={postingHealthProfile}
                  type={"submit"}
                  className="text-2xl font-bold text-orange"
                >
                  {postingHealthProfile ? (
                    <CSSLoader className="w-8 h-8" color="orange" />
                  ) : (
                    "Save"
                  )}
                </button>
              }
            />
            <div className="px-12 py-6 overflow-y-auto bg-white h-eliminateHeaderWithBottomTab md:h-eliminateHeader">
              <MedicalAssistant
                content={
                  <p>
                    Please tell us about your surgeries and hospitalizations.
                  </p>
                }
              />
              <Spacer height={26} />
              <HealthProfileQuestion
                text={"Have you ever had surgery or been hospitalized?"}
              />
              <div className="flex items-center mt-8">
                {YESNO?.map((option, optionIndex) => {
                  return (
                    <div className="flex flex-1" key={optionIndex}>
                      <HealthProfileRadio
                        text={option}
                        selected={values?.hospitalized_before === option}
                        onClick={() => {
                          setFieldValue("hospitalized_before", option);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
              {errors?.hospitalized_before && submitCount ? (
                <p className="mt-4 text-base font-bold text-red">
                  {errors?.hospitalized_before}
                </p>
              ) : null}

              {values?.hospitalized_before === "Yes" ? (
                <AddSurgeriesHospitalisations
                  values={values?.surgeriesHospitalisations}
                  setFieldValue={setFieldValue}
                />
              ) : null}

              {errors?.surgeriesHospitalisations && submitCount ? (
                <p className="text-base font-bold text-red">
                  {errors?.surgeriesHospitalisations}
                </p>
              ) : null}
            </div>
            <FormikErrorFocus align={"bottom"} ease={"linear"} duration={500} />
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default SurgeriesHospitalisations;
