import React, { useEffect, useMemo } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import useHealthProfile from "hooks/useHealthProfile";
import LoaderComponent from "components/loader/LoaderComponent";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import ListItemWrapper from "./ListItemWrapper";
import { health_profile_sections } from "helpers/enum";
import { getHealthProfileSectionStatus } from "./getHealthProfileSectionStatus";
import { getMedicalHistoryView } from "./medicalHistory/getMedicalHistoryView";
import { getAllergiesView } from "./allergies/getAllergiesView";
import { getMedicationsView } from "./medications/getMedicationsView";
import { getSurgeriesHospitalisationsView } from "./surgeriesHospitalisations/getSurgeriesHospitalisationsView";
import { getFamilyHistoryView } from "./familyHistory/getFamilyHistoryView";
import { getFemaleHistoryView } from "./femaleHealth/getFemaleHistoryView";
import SimpleHeader from "components/header/SimpleHeader";

function HealthProfileView() {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { health_id } = useParams();
  const {
    gettingUserHealthProfile,
    healthPayload,
    getHealthProfile,
  } = useHealthProfile();

  const completed_percentage = useMemo(
    () => healthPayload?.health_profile?.completed_percentage ?? 0,
    [healthPayload]
  );

  useEffect(() => {
    if (health_id) {
      getHealthProfile(health_id);
    }
  }, [health_id, getHealthProfile]);

  if (gettingUserHealthProfile) {
    return <LoaderComponent showLoader={{ state: true }} />;
  }

  return (
    <div className="h-screen bg-veryLightBlue">
      <SimpleHeader
        title="Health Profile"
        showBackButton
        rightActionView={
          <div className="w-20 h-20">
            <CircularProgressbar
              value={completed_percentage}
              text={`${completed_percentage}%`}
              className={"font-bold"}
              strokeWidth={11}
              styles={buildStyles({
                rotation: 0,
                pathTransitionDuration: 1,
                pathColor: "#ff8548",
                textColor: "#101347",
                trailColor: "#d6d6d6",
                backgroundColor: "#3e98c7",
              })}
            />
          </div>
        }
      />
      <div className="py-8 pl-12 pr-20 overflow-y-auto bg-white h-eliminateHeaderWithBottomTab md:h-eliminateHeader">
        <MedicalAssistant
          content={
            <p>
              Please complete your health profile so your doctors have a
              complete picture of your current and past medical history.
            </p>
          }
        />
        <div>
          {/* Show link if even one of the prescription tables have one or more records */}
          {healthPayload &&
          healthPayload.prescription_history &&
          Object.values(healthPayload?.prescription_history).some(
            (item) => item.length > 0
          ) ? (
            <ListItemWrapper
              itemName={health_profile_sections?.prescription_history}
              onClick={() => history.push(`${url}/prescription-history`)}
            >
              {getMedicalHistoryView(healthPayload?.health_profile)}
            </ListItemWrapper>
          ) : (
            <div />
          )}
          <ListItemWrapper
            itemName={health_profile_sections?.medical_history}
            status={getHealthProfileSectionStatus({
              healthPayload,
              section: health_profile_sections?.medical_history,
            })}
            onClick={() => history.push(`${url}/medical-history`)}
          >
            {getMedicalHistoryView(healthPayload?.health_profile)}
          </ListItemWrapper>
          <ListItemWrapper
            itemName={health_profile_sections?.allergies}
            status={getHealthProfileSectionStatus({
              healthPayload,
              section: health_profile_sections?.allergies,
            })}
            onClick={() => history.push(`${url}/allergies`)}
          >
            {getAllergiesView(healthPayload?.health_profile)}
          </ListItemWrapper>
          <ListItemWrapper
            itemName={health_profile_sections?.medications}
            status={getHealthProfileSectionStatus({
              healthPayload,
              section: health_profile_sections?.medications,
            })}
            onClick={() => history.push(`${url}/medications`)}
          >
            {getMedicationsView(healthPayload?.health_profile)}
          </ListItemWrapper>
          <ListItemWrapper
            itemName={
              health_profile_sections?.surgical_procedures_or_hospitalisations
            }
            status={getHealthProfileSectionStatus({
              healthPayload,
              section:
                health_profile_sections?.surgical_procedures_or_hospitalisations,
            })}
            onClick={() => history.push(`${url}/surgeries-hospitalisations`)}
          >
            {getSurgeriesHospitalisationsView(healthPayload?.health_profile)}
          </ListItemWrapper>
          <ListItemWrapper
            itemName={health_profile_sections?.family_history}
            status={getHealthProfileSectionStatus({
              healthPayload,
              section: health_profile_sections?.family_history,
            })}
            onClick={() => history.push(`${url}/family-history`)}
          >
            {getFamilyHistoryView(healthPayload?.health_profile)}
          </ListItemWrapper>
          {healthPayload?.gender &&
          healthPayload?.gender.toLowerCase() === "female" ? (
            <ListItemWrapper
              itemName={"Female Health"}
              status={getHealthProfileSectionStatus({
                healthPayload,
                section: health_profile_sections?.female_health_care,
              })}
              onClick={() => history.push(`${url}/female-health`)}
            >
              {getFemaleHistoryView(healthPayload?.health_profile)}
            </ListItemWrapper>
          ) : null}
          <ListItemWrapper
            itemName={health_profile_sections?.social_history}
            status={getHealthProfileSectionStatus({
              healthPayload,
              section: health_profile_sections?.social_history,
            })}
            onClick={() => history.push(`${url}/social-history`)}
          ></ListItemWrapper>
        </div>
      </div>
    </div>
  );
}

export default HealthProfileView;
